import { useNavigate, generatePath } from 'react-router-dom'
import { routes } from '../routes'
import { useProducts } from './index'

const useRedirect = () => {
  const { hasMsaAccess } = useProducts()
  const navigate = useNavigate()

  const msaPath = generatePath(routes['assessment-details'].path, { assessmentId: 'manager-skill-assessment' })
  const skillBitesPath = routes['skill-bites'].path

  const redirectRoute = hasMsaAccess ? msaPath : skillBitesPath
  const standaloneRedirect = () => navigate(redirectRoute, { replace: true })
  return { standaloneRedirect }
}

export default useRedirect
