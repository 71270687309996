import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import name from './name'
import { CREATE_ACCOUNT_STEP } from '../../pages/b2c-signup/components/progress-bar'

const initialState = {
  step: CREATE_ACCOUNT_STEP,
  account: {
    firstName: '',
    lastName: '',
    email: '',
    username: ''
  },
  defaultPlan: '', // Set from url param
  paymentToken: '',
  plan: null,
  availablePlans: {
    status: 'idle',
    data: []
  },
  currency: 'GBP',
  coupon: [],
  paymentResponse: {},
  checkoutPricing: {},
  paymentInfoSubmitted: false,
  paymentError: null,
  disabled: false
}

export const fetchPlans = createAsyncThunk('b2c-signup/subscription-plans', async (planCode) => {
  const response = await fetch(`${process.env.REACT_APP_API_GATEWAY_ADDRESS}/subscription-plans?planCode=${planCode}`)
  return response.json()
})

export const content = createSlice({
  name,
  initialState,
  reducers: {
    setDefaultPlan: (state, action) => {
      state.defaultPlan = action.payload
    },
    setCurrentStep: (state, action) => {
      state.step = action.payload
    },
    setPaymentToken: (state, action) => {
      state.paymentToken = action.payload
    },
    setPaymentResponse: (state, action) => {
      state.paymentResponse = action.payload
    },
    setPlan: (state, action) => {
      state.plan = action.payload
    },
    setAvailablePlans: (state, action) => {
      state.availablePlans = action.payload
    },
    setCoupon: (state, action) => {
      state.coupon = [action.payload]
    },
    setCurrency: (state, action) => {
      state.currency = [action.payload]
    },
    setAccount: (state, action) => {
      state.account = {
        ...state.account,
        ...action.payload
      }
    },
    setCheckoutPricing: (state, action) => {
      state.checkoutPricing = action.payload
    },
    setPaymentInfoSubmitted: (state, action) => {
      state.paymentInfoSubmitted = action.payload
    },

    setPaymentError: (state, action) => {
      state.paymentError = action.payload
    },

    clearPaymentError: (state, action) => {
      if (state.paymentError) {
        state.paymentError = null
      }
    },
    setDisabled: (state, action) => {
      state.disabled = action.payload
    }

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlans.pending, (state) => {
        state.availablePlans.status = 'loading'
      })
      .addCase(fetchPlans.fulfilled, (state, action) => {
        state.availablePlans.status = 'succeeded'
        state.availablePlans.data = action.payload
        const planFromUrl = action.payload.find(plan => plan.code === state.defaultPlan)
        // Only set the default plan the first time the plans are fetched, otherwise it overrides the selected plan
        if (state.plan === null) {
          state.plan = planFromUrl || action.payload[0]
        }
      })
      .addCase(fetchPlans.rejected, (state) => {
        state.availablePlans.status = 'failed'
      })
  }
})

export const {
  setDefaultPlan,
  setCurrentStep,
  setPaymentToken,
  setPaymentResponse,
  setPlan,
  setAvailablePlans,
  setCoupon,
  setAccount,
  setCheckoutPricing,
  setPaymentInfoSubmitted,
  setPaymentError,
  clearPaymentError,
  setDisabled
} = content.actions

export default content.reducer
