import * as actions from './slice'
import { stackAlert } from '../toast/helpers'

export const getHomeContent = {
  eventName: 'contentGetTenantHomeContent',
  onSuccess: {
    action: actions.setHomeContent,
    redux: true
  },
  onError: {
    action: stackAlert('Unable to get more content', 'error'),
    redux: true
  }
}

export const getPromotedContent = {
  eventName: 'promotedContentGetHomePromotedContent',
  onStart: {
    action: actions.setPromotedContentHasBeenTriggered,
    redux: true
  },
  onSuccess: {
    action: actions.setPromotedContent,
    redux: true
  },
  onError: {
    action: stackAlert('Unable to get promoted content', 'error'),
    redux: true
  }
}
export const getRecommendedVideos = {
  eventName: 'contentGetHomeVideos',
  onSuccess: {
    action: actions.setRecommendedVideos,
    redux: true
  }
}

export const getLastRead = {
  eventName: 'userRecommendationGetLastRead',
  onSuccess: {
    action: actions.setLastRead,
    redux: true
  }
}
export const getRecommendedCategories = {
  eventName: 'reportingGetTopCategories',
  onSuccess: {
    action: actions.setRecommendedCategories,
    redux: true
  }
}
export const getForYouContent = {
  eventName: 'contentGetForYouContent',
  onSuccess: {
    action: actions.setForYouContent,
    redux: true
  },
  onError: {
    action: stackAlert('Unable to get content for you', 'error'),
    redux: true
  }
}
