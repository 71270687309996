import { useSession } from '@emerald-works/react-auth'
import { useIdleTimer } from 'react-idle-timer'
import useLogout from './useLogout'
import { useTenant } from '@emerald-works-nova/auth'
import { SIGN_IN_PROVIDER_TYPE_STORAGE_KEY, SIGN_IN_USERNAME_PASSWORD } from '../pages/sign-in/components/sign-in'

const INACTIVIY_TIMEOUT =
  process.env.REACT_APP_INACTIVIY_TIMEOUT ?? 60 * 60 * 1000

const useSessionInactivity = () => {
  const { isAuthenticated, user } = useSession()
  const isBrowserUser = !isAuthenticated
  const { logout } = useLogout()
  const tenant = useTenant()

  const hasSSOSetup = tenant.loginProviders.filter(lp => lp.providerType === 'SAML' && lp.providerName !== 'internal').length > 0
  const hasIdentitiesAttributes = !!user?.attributes?.identities
  // Since cognito doesn't return a way to detect if the user has authenticated via SSO
  // we will consider a SSO User if the user has identities attributes and their tenant has sso setup
  // For those users we will skip the session inactivity timeout, since the SSO will re authenticate the user
  const loginProviderType = localStorage.getItem(SIGN_IN_PROVIDER_TYPE_STORAGE_KEY)
  const shouldSkipSessionInactivity = hasSSOSetup && hasIdentitiesAttributes && loginProviderType !== SIGN_IN_USERNAME_PASSWORD

  const onIdle = () => {
    if (window.location.pathname === '/login' || isBrowserUser || shouldSkipSessionInactivity) return

    logout('/login')
  }

  useIdleTimer({ timeout: INACTIVIY_TIMEOUT, onIdle })
}

export default useSessionInactivity
