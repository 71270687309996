import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { freeAccountCtaSlice } from '../reducers'

const DEFAULT_CTA_MODAL_MESSAGES = {
  BOOKMARK: "You'll need to be a Mintools Member to continue",
  ADD_CONTENT_BOOKMARK: "You'll need to be a Mintools Member to continue",
  SUBSCRIBE_NEWSLETTER: 'Please create an account to subscribe to the newsletter',
  LIKE: "You'll need to be a Mintools Member to continue"
}

const useFreeAccountCta = () => {
  const dispatch = useDispatch()

  const openFreeAccountModalForLike = useCallback(() => {
    dispatch(freeAccountCtaSlice.actions.openCtaModal({ message: DEFAULT_CTA_MODAL_MESSAGES.LIKE }))
  }, [dispatch])

  const openFreeAccountModalForBookmark = useCallback(() => {
    dispatch(freeAccountCtaSlice.actions.openCtaModal({ message: DEFAULT_CTA_MODAL_MESSAGES.BOOKMARK }))
  }, [dispatch])

  const openFreeAccountModalForAddContentToBookmark = useCallback(() => {
    dispatch(freeAccountCtaSlice.actions.openCtaModal({ message: DEFAULT_CTA_MODAL_MESSAGES.ADD_CONTENT_BOOKMARK }))
  }, [dispatch])

  const openFreeAccountModalForSubscribeToNewsletter = useCallback(() => {
    dispatch(freeAccountCtaSlice.actions.openCtaModal({ message: DEFAULT_CTA_MODAL_MESSAGES.SUBSCRIBE_NEWSLETTER, appendSubscribeFreeNewsletter: true }))
  }, [dispatch])

  return {
    openFreeAccountModalForBookmark,
    openFreeAccountModalForAddContentToBookmark,
    openFreeAccountModalForSubscribeToNewsletter,
    openFreeAccountModalForLike
  }
}

export default useFreeAccountCta
