import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material'
import { CloseOutlined } from '@mui/icons-material'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { freeAccountCtaSlice } from '../../reducers'
import { ActionButton } from '../action-buttons'
import { FreeAccountCtaLock } from '../icons'
import { useStyles } from './styles'
import { useExternalApp } from '../../hooks'

const FreeAccountCtaModal = () => {
  const isOpen = useSelector(freeAccountCtaSlice.selectors.selectFreeAccountCtaOpen)
  const message = useSelector(freeAccountCtaSlice.selectors.selectFreeAccountCtaMessage)
  const classes = useStyles()
  const dispatch = useDispatch()
  const { getMtUrl } = useExternalApp()

  const handleClose = useCallback(() => {
    dispatch(freeAccountCtaSlice.actions.closeCtaModal())
  }, [dispatch])

  return (
    <Dialog onClose={handleClose} open={isOpen} classes={{ paper: classes.root }}>
      <DialogTitle className={classes.titleBar}>
        <IconButton
          aria-label='close free cta modal'
          data-test='free-account-cta-close'
          onClick={handleClose}
          size='large'
        >
          <CloseOutlined />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.contentContainer}>
        <FreeAccountCtaLock className={classes.lockIcon} />
        <Typography className={classes.message} data-test='free-account-cta-message'>{message}</Typography>
        <Box width='100%'>
          <ActionButton
            variant='primary'
            label='Join Today'
            data-test='free-account-cta-create-account'
            href={getMtUrl('joinlive')}
          />
        </Box>
        <Typography className={classes.goToSignMessage}>Already have an account? <Link to='/login' data-test='free-account-cta-sign-in' onClick={handleClose}>Sign in</Link></Typography>
      </DialogContent>
    </Dialog>
  )
}

export default FreeAccountCtaModal
