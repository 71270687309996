import Auth from '@aws-amplify/auth'
import { useSession } from '@emerald-works/react-auth'
import { useConnectionContext } from '@emerald-works/react-event-bus-client'
import { useCallback } from 'react'

const shouldRemove = (cookie) => !/cookieyes|fallback|dd|_dd_s|cky-consent|Cognito/.test(cookie)

const useLogout = () => {
  const session = useSession()
  const connection = useConnectionContext()

  const logout = useCallback(
    async path => {
      const deleteCookies = () => {
        const cookies = document.cookie.split(/; */)

        cookies.forEach(cookie => {
          const [name] = cookie.split('=')

          if (shouldRemove(name)) {
            document.cookie = `${name}=;path=/;max-age=-1`
            document.cookie = `${name}=;path=${location.pathname};max-age=-1`
          }

          // Attempt to delete cookies set with subdomains and paths
          const domainParts = location.hostname.split('.')
          while (domainParts.length >= 1) {
            if (shouldRemove(name)) {
              document.cookie = `${name}=;domain=${domainParts.join('.')};path=/;max-age=-1`
              document.cookie = `${name}=;domain=${domainParts.join('.')};path=${location.pathname};max-age=-1`
            }
            domainParts.shift()
          }
        })
      }

      const clearLocalStorage = () => {
        localStorage.clear()
      }

      const reload = async () => {
        try {
          await session.updateSessionContext()
          await connection.reloadConnection()
          window.location.href = new URL(path, window.location.href)
        } catch (err) {
          window.location.href = new URL(path, window.location.href)
        }
      }
      if (window.DD_RUM && typeof window.DD_RUM.clearUser === 'function') {
        window.DD_RUM.clearUser()
      } else {
        console.log('clearUser is not available')
      }
      if (window.DD_RUM && typeof window.DD_RUM.clearGlobalContext === 'function') {
        window.DD_RUM.clearGlobalContext()
      } else {
        console.log('clearGlobalContext is not available')
      }
      await Auth.signOut()
      deleteCookies()
      clearLocalStorage()
      await reload()
    },
    [session, connection]
  )

  return { logout }
}

export default useLogout
