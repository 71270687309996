import name from './name'

const selectSliceData = state => state[name]
const selectCurrentStep = state => selectSliceData(state).step
const selectPaymentToken = state => selectSliceData(state).paymentToken
const selectPlan = state => selectSliceData(state).plan
const selectDefaultPlan = state => selectSliceData(state).defaultPlan
const selectAvailablePlans = state => selectSliceData(state).availablePlans
const selectCoupon = state => selectSliceData(state).coupon
const selectPaymentResponse = state => selectSliceData(state).paymentResponse
const selectPaymentInfoSubmitted = state => selectSliceData(state).paymentInfoSubmitted
const selectCurrency = state => selectSliceData(state).currency
const selectAccount = state => selectSliceData(state).account
const selectCheckoutPricing = state => selectSliceData(state).checkoutPricing
const selectPaymentError = state => selectSliceData(state).paymentError
const selectDisabled = state => selectSliceData(state).disabled

export {
  selectCurrentStep,
  selectPaymentToken,
  selectPlan,
  selectCoupon,
  selectPaymentResponse,
  selectAvailablePlans,
  selectDefaultPlan,
  selectCurrency,
  selectAccount,
  selectCheckoutPricing,
  selectPaymentInfoSubmitted,
  selectPaymentError,
  selectDisabled
}
