import React, { useEffect } from 'react'
// import google-adsense.css
import './google_ad.css'
import GoogleAdCta from './google-ad-cta'

const GoogleAd = ({ location = 'not set' }) => {
  // initialise this advert on component load
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({})
    } catch (error) {
      console.debug('*** Google adsense component error', error)
    }
  }, [])

  const isDev = ['mt3.dev', 'ewnova.dev', 'localhost'].some((host) => window.location.hostname.endsWith(host))

  if (!isDev) {
    return (
      <>
        <div className='google_adsense_container'>
          <ins
            className={`adsbygoogle ${location}`}
            data-ad-client={process.env.REACT_APP_GOOGLE_ADSENSE_CLIENT}
            data-ad-slot={process.env.REACT_APP_GOOGLE_ADSENSE_SLOT}
            data-ad-format='auto'
            data-full-width-responsive='true'
          />
        </div>
        <GoogleAdCta location={location} />
      </>
    )
  } else {
    return (
      <>
        <div className='google_adsense_container_placeholder'>
          <div className={`adsbygoogle ${location}`}>
            PLACEHOLDER GOOGLE ADSENSE ADVERT<br />
            {location}
          </div>
        </div>
        <GoogleAdCta location={location} />
      </>
    )
  }
}

export default GoogleAd
