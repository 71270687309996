import makeStyles from '@mui/styles/makeStyles'

export const appBarHeight = 64

export const useStyles = makeStyles(
  ({ palette, spacing, breakpoints, typography }) => ({
    appBar: {
      backgroundColor: palette.background.default,
      height: appBarHeight,
      boxShadow: 'none',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      borderBottom: `2px solid ${palette.primary.main}`
    },
    toolbar: ({ bps }) => ({
      height: '100%',
      width: '100%',
      maxWidth: 1440,
      justifyContent: 'space-between',
      '& > *:not(:last-child)': {
        marginRight: spacing(2),
        [breakpoints.down('sm')]: {
          marginRight: 0
        }
      }
    }),
    newsletterPopoverRoot: {
      width: '100%'
    },
    newsletterPopoverPaper: {
      width: '100%',
      padding: 20,
      display: 'flex',
      justifyContent: 'center'
    },
    searchContainer: ({ bps }) => ({
      [breakpoints.up(bps.tablet)]: {
        flex: '1 1 0'
      }
    }),
    closeButton: {
      width: 40,
      height: 40
    },
    logoWrapper: {
      display: 'flex',
      alignItems: 'center'
    },
    logo: {
      objectFit: 'contain',
      [breakpoints.down('sm')]: {
        margin: 'auto'
      }
    },
    mindsetBubble: {
      position: 'fixed',
      bottom: '20px',
      right: '20px',
      width: '63px',
      height: '63px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: palette.primary.main,
      borderRadius: '50%',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      cursor: 'pointer'
    }
  })
)
