import name from './name'

const selectSliceData = state => state[name]

const selectHomeContent = state => selectSliceData(state).homeContent
const selectRandomHash = state => selectSliceData(state).randomHash
const selectContentError = state => selectSliceData(state).error
const selectPromotedContent = state => selectSliceData(state).promotedContent
const selectRecommendedVideos = state => selectSliceData(state).recommendedVideos
const selectRecommendedRead = state => selectSliceData(state).recommendedRead
const selectRecommendedCategories = state => selectSliceData(state).recommendedCategories
const selectPromotedContentHasBeenTriggered = state => selectSliceData(state).promotedContentHasBeenTriggered
const selectForYouContent = state => selectSliceData(state).forYouContent

export {
  selectHomeContent,
  selectRandomHash,
  selectPromotedContent,
  selectPromotedContentHasBeenTriggered,
  selectContentError,
  selectRecommendedCategories,
  selectRecommendedRead,
  selectRecommendedVideos,
  selectForYouContent
}
