import { useTenant } from '@emerald-works-nova/auth'

export const mtUrlMap = {
  howitworks: 'https://www.mindtools.com/how-it-works/',
  blog: 'https://www.mindtools.com/blog/',
  newsletter: 'https://www.mindtools.com/newsletter',
  forbusiness: 'https://www.mindtools.com/solutions/organizations/',
  join: 'https://www.mindtools.com/signup/',
  joinFree: 'https://www.mindtools.com/signup/free',
  joinlive: 'https://www.mindtools.com/signup/',
  store: 'https://store.mindtools.com',
  about: 'https://www.mindtools.com/about-us/',
  faq: 'https://www.mindtools.com/faq/',
  content: 'https://www.mindtools.com/content/',
  contact: 'https://www.mindtools.com/contact-us/',
  privacy: 'https://www.mindtools.com/about-us/policies/privacy',
  cookie: 'https://www.mindtools.com/about-us/policies/cookie',
  tandc: 'https://www.mindtools.com/about-us/policies/tandc',
  b2bContact: 'https://mindtools.com/business/contact-us',
  termsOfService: 'https://www.mindtools.com/policies/terms-of-website-sale/'
}

const useExternalApp = () => {
  const tenant = useTenant()

  const getMtUrl = key => {
    const isLive = () =>
      !location.hostname.endsWith('mt3.dev') &&
      !location.hostname.endsWith('ewnova.dev') &&
      !location.hostname.endsWith('localhost')

    const envKey = key === 'join' && isLive() ? 'joinlive' : key
    return mtUrlMap[envKey] || 'https://mindtools.com'
  }

  let arkUrl

  const isLocal = location.hostname.match(/localhost/)
  const host = window.location.host
  const arkName = isLocal
    ? process.env.REACT_APP_ARK_DOMAIN ?? 'localhost:3001'
    : host.replace('toolkit', 'ark')

  // prefix url with tenantKey if not present
  if (arkName.split('.')[0] !== tenant.tenantKey) {
    arkUrl = `https://${tenant.tenantKey}.${arkName}/ark/`
  } else {
    arkUrl = `https://${arkName}/ark/`
  }

  // temporary fix for live while the apps have different domains
  if (process.env.REACT_APP_ARK_TEMP_REDIRECT === 'true' && location.hostname.match(/mindtools.com$/)) {
    arkUrl = `https://${tenant.tenantKey}.mindtools.com/ark/`
  }

  const reportingUrl = arkUrl.replace('/ark/', '/reporting/')

  return { arkUrl, reportingUrl, getMtUrl }
}

export default useExternalApp
