/*
  These languages are supported by the Google Cloud Translate API
  More details: https://cloud.google.com/translate/docs/languages

  The languages are in the format of an array of objects with the following properties:
    - label: the name of the language
    - value: the language code
*/
export const googleTranslateLanguages = [
  { label: 'Afrikaans', translatedLabel: 'Afrikaans', value: 'af' },
  { label: 'Albanian', translatedLabel: 'Shqip', value: 'sq' },
  { label: 'Amharic', translatedLabel: 'አማርኛ', value: 'am' },
  { label: 'Arabic', translatedLabel: 'العربية', value: 'ar' },
  { label: 'Armenian', translatedLabel: 'Հայերեն', value: 'hy' },
  { label: 'Assamese', translatedLabel: 'অসমীয়া', value: 'as' },
  { label: 'Aymara', translatedLabel: 'Aymar aru', value: 'ay' },
  { label: 'Azerbaijani', translatedLabel: 'Azərbaycan', value: 'az' },
  { label: 'Bambara', translatedLabel: 'Bamanankan', value: 'bm' },
  { label: 'Basque', translatedLabel: 'Euskara', value: 'eu' },
  { label: 'Belarusian', translatedLabel: 'Беларуская', value: 'be' },
  { label: 'Bengali', translatedLabel: 'বাংলা', value: 'bn' },
  { label: 'Bhojpuri', translatedLabel: 'भोजपुरी', value: 'bho' },
  { label: 'Bosnian', translatedLabel: 'Bosanski', value: 'bs' },
  { label: 'Bulgarian', translatedLabel: 'Български', value: 'bg' },
  { label: 'Catalan', translatedLabel: 'Català', value: 'ca' },
  { label: 'Cebuano', translatedLabel: 'Cebuano', value: 'ceb' },
  { label: 'Chichewa', translatedLabel: 'Chichewa', value: 'ny' },
  { label: 'Chinese (Simplified)', translatedLabel: '简体中文', value: 'zh-cn' },
  { label: 'Chinese (Traditional)', translatedLabel: '繁體中文', value: 'zh-tw' },
  { label: 'Corsican', translatedLabel: 'Corsu', value: 'co' },
  { label: 'Croatian', translatedLabel: 'Hrvatski', value: 'hr' },
  { label: 'Czech', translatedLabel: 'Čeština', value: 'cs' },
  { label: 'Danish', translatedLabel: 'Dansk', value: 'da' },
  { label: 'Divehi', translatedLabel: 'ދިވެހި', value: 'dv' },
  { label: 'Dogri', translatedLabel: 'डोगरी', value: 'doi' },
  { label: 'Dutch', translatedLabel: 'Nederlands', value: 'nl' },
  { label: 'English', translatedLabel: 'English', value: 'en' },
  { label: 'Esperanto', translatedLabel: 'Esperanto', value: 'eo' },
  { label: 'Estonian', translatedLabel: 'Eesti', value: 'et' },
  { label: 'Ewe', translatedLabel: 'Eʋegbe', value: 'ee' },
  { label: 'Filipino', translatedLabel: 'Filipino', value: 'tl' },
  { label: 'Finnish', translatedLabel: 'Suomi', value: 'fi' },
  // { label: 'French', translatedLabel: 'Français', value: 'fr' },
  { label: 'French (French)', translatedLabel: 'Français - Français', value: 'fr-FR' },
  { label: 'French (Canadian)', translatedLabel: 'Français - Canadien', value: 'fr-CA' },
  { label: 'Frisian', translatedLabel: 'Frysk', value: 'fy' },
  { label: 'Galician', translatedLabel: 'Galego', value: 'gl' },
  { label: 'Ganda', translatedLabel: 'Luganda', value: 'lg' },
  { label: 'Georgian', translatedLabel: 'ქართული', value: 'ka' },
  { label: 'German', translatedLabel: 'Deutsch', value: 'de' },
  { label: 'Greek', translatedLabel: 'Ελληνικά', value: 'el' },
  { label: 'Guarani', translatedLabel: 'Avañeʼẽ', value: 'gn' },
  { label: 'Gujarati', translatedLabel: 'ગુજરાતી', value: 'gu' },
  { label: 'Haitian Creole', translatedLabel: 'Kreyòl Ayisyen', value: 'ht' },
  { label: 'Hausa', translatedLabel: 'Hausa', value: 'ha' },
  { label: 'Hawaiian', translatedLabel: 'ʻŌlelo Hawaiʻi', value: 'haw' },
  { label: 'Hebrew', translatedLabel: 'עברית', value: 'iw' },
  { label: 'Hindi', translatedLabel: 'हिन्दी', value: 'hi' },
  { label: 'Hmong', translatedLabel: 'Hmoob', value: 'hmn' },
  { label: 'Hungarian', translatedLabel: 'Magyar', value: 'hu' },
  { label: 'Icelandic', translatedLabel: 'Íslenska', value: 'is' },
  { label: 'Igbo', translatedLabel: 'Asụsụ Igbo', value: 'ig' },
  { label: 'Iloko', translatedLabel: 'Ilocano', value: 'ilo' },
  { label: 'Indonesian', translatedLabel: 'Bahasa Indonesia', value: 'id' },
  { label: 'Irish Gaelic', translatedLabel: 'Gaeilge', value: 'ga' },
  { label: 'Italian', translatedLabel: 'Italiano', value: 'it' },
  { label: 'Japanese', translatedLabel: '日本語', value: 'ja' },
  { label: 'Javanese', translatedLabel: 'Basa Jawa', value: 'jw' },
  { label: 'Kannada', translatedLabel: 'ಕನ್ನಡ', value: 'kn' },
  { label: 'Kazakh', translatedLabel: 'Қазақ тілі', value: 'kk' },
  { label: 'Khmer', translatedLabel: 'ខ្មែរ', value: 'km' },
  { label: 'Kinyarwanda', translatedLabel: 'Ikinyarwanda', value: 'rw' },
  { label: 'Konkani', translatedLabel: 'कोंकणी', value: 'gom' },
  { label: 'Korean', translatedLabel: '한국어', value: 'ko' },
  { label: 'Krio', translatedLabel: 'Krio', value: 'kri' },
  { label: 'Kurdish (Kurmanji)', translatedLabel: 'Kurdî', value: 'ku' },
  { label: 'Kurdish (Sorani)', translatedLabel: 'کوردیی ناوەڕاست', value: 'ckb' },
  { label: 'Kyrgyz', translatedLabel: 'Кыргызча', value: 'ky' },
  { label: 'Lao', translatedLabel: 'ລາວ', value: 'lo' },
  { label: 'Latin', translatedLabel: 'Latina', value: 'la' },
  { label: 'Latvian', translatedLabel: 'Latviešu', value: 'lv' },
  { label: 'Lingala', translatedLabel: 'Lingála', value: 'ln' },
  { label: 'Lithuanian', translatedLabel: 'Lietuvių', value: 'lt' },
  { label: 'Luxembourgish', translatedLabel: 'Lëtzebuergesch', value: 'lb' },
  { label: 'Macedonian', translatedLabel: 'Македонски', value: 'mk' },
  { label: 'Maithili', translatedLabel: 'मैथिली', value: 'mai' },
  { label: 'Malagasy', translatedLabel: 'Malagasy', value: 'mg' },
  { label: 'Malay', translatedLabel: 'Bahasa Melayu', value: 'ms' },
  { label: 'Malayalam', translatedLabel: 'മലയാളം', value: 'ml' },
  { label: 'Maltese', translatedLabel: 'Malti', value: 'mt' },
  { label: 'Maori', translatedLabel: 'Te Reo Māori', value: 'mi' },
  { label: 'Marathi', translatedLabel: 'मराठी', value: 'mr' },
  { label: 'Meiteilon (Manipuri)', translatedLabel: 'ꯃꯤꯇꯩꯂꯣꯟ (মণিপুরী)', value: 'mni-Mtei' },
  { label: 'Mizo', translatedLabel: 'Mizo', value: 'lus' },
  { label: 'Mongolian', translatedLabel: 'Монгол', value: 'mn' },
  { label: 'Myanmar (Burmese)', translatedLabel: 'ဗမာစာ', value: 'my' },
  { label: 'Nepali', translatedLabel: 'नेपाली', value: 'ne' },
  { label: 'Northern Sotho', translatedLabel: 'Sesotho sa Leboa', value: 'nso' },
  { label: 'Norwegian', translatedLabel: 'Norsk', value: 'no' },
  { label: 'Odia (Oriya)', translatedLabel: 'ଓଡ଼ିଆ', value: 'or' },
  { label: 'Oromo', translatedLabel: 'Afaan Oromoo', value: 'om' },
  { label: 'Pashto', translatedLabel: 'پښتو', value: 'ps' },
  { label: 'Persian', translatedLabel: 'فارسی', value: 'fa' },
  { label: 'Polish', translatedLabel: 'Polski', value: 'pl' },
  { label: 'Portuguese', translatedLabel: 'Português', value: 'pt' },
  { label: 'Punjabi', translatedLabel: 'ਪੰਜਾਬੀ', value: 'pa' },
  { label: 'Quechua', translatedLabel: 'Runasimi', value: 'qu' },
  { label: 'Romanian', translatedLabel: 'Română', value: 'ro' },
  { label: 'Russian', translatedLabel: 'Русский', value: 'ru' },
  { label: 'Samoan', translatedLabel: 'Gagana Sāmoa', value: 'sm' },
  { label: 'Sanskrit', translatedLabel: 'संस्कृतम्', value: 'sa' },
  { label: 'Scots Gaelic', translatedLabel: 'Gàidhlig', value: 'gd' },
  { label: 'Serbian', translatedLabel: 'Српски', value: 'sr' },
  { label: 'Sesotho', translatedLabel: 'Sesotho', value: 'st' },
  { label: 'Shona', translatedLabel: 'ChiShona', value: 'sn' },
  { label: 'Sindhi', translatedLabel: 'سنڌي', value: 'sd' },
  { label: 'Sinhala', translatedLabel: 'සිංහල', value: 'si' },
  { label: 'Slovak', translatedLabel: 'Slovenčina', value: 'sk' },
  { label: 'Slovenian', translatedLabel: 'Slovenščina', value: 'sl' },
  { label: 'Somali', translatedLabel: 'Soomaali', value: 'so' },
  { label: 'Spanish', translatedLabel: 'Español', value: 'es' },
  { label: 'Sundanese', translatedLabel: 'Basa Sunda', value: 'su' },
  { label: 'Swahili', translatedLabel: 'Kiswahili', value: 'sw' },
  { label: 'Swedish', translatedLabel: 'Svenska', value: 'sv' },
  { label: 'Tajik', translatedLabel: 'Тоҷикӣ', value: 'tg' },
  { label: 'Tamil', translatedLabel: 'தமிழ்', value: 'ta' },
  { label: 'Tatar', translatedLabel: 'татарча', value: 'tt' },
  { label: 'Telugu', translatedLabel: 'తెలుగు', value: 'te' },
  { label: 'Thai', translatedLabel: 'ไทย', value: 'th' },
  { label: 'Tigrinya', translatedLabel: 'ትግርኛ', value: 'ti' },
  { label: 'Tsonga', translatedLabel: 'Xitsonga', value: 'ts' },
  { label: 'Turkish', translatedLabel: 'Türkçe', value: 'tr' },
  { label: 'Turkmen', translatedLabel: 'Türkmen', value: 'tk' },
  { label: 'Twi', translatedLabel: 'Twi', value: 'ak' },
  { label: 'Ukrainian', translatedLabel: 'Українська', value: 'uk' },
  { label: 'Urdu', translatedLabel: 'اردو', value: 'ur' },
  { label: 'Uyghur', translatedLabel: 'ئۇيغۇرچە', value: 'ug' },
  { label: 'Uzbek', translatedLabel: 'Oʻzbekcha', value: 'uz' },
  { label: 'Vietnamese', translatedLabel: 'Tiếng Việt', value: 'vi' },
  { label: 'Welsh', translatedLabel: 'Cymraeg', value: 'cy' },
  { label: 'Xhosa', translatedLabel: 'isiXhosa', value: 'xh' },
  { label: 'Yiddish', translatedLabel: 'ייִדיש', value: 'yi' },
  { label: 'Yoruba', translatedLabel: 'Yorùbá', value: 'yo' },
  { label: 'Zulu', translatedLabel: 'isiZulu', value: 'zu' }
]
