import { Link, List, ListItem } from '@mui/material'
import {
  HelpTwoTone,
  InfoTwoTone,
  StorefrontTwoTone,
  PersonOutlineOutlined,
  CorporateFareOutlined
} from '@mui/icons-material'
import React from 'react'
import { useExternalApp, useFormFactor } from '../../hooks'
import { ActionButton } from '../action-buttons'
import makeStyles from '@mui/styles/makeStyles'
import { useTenant } from '@emerald-works-nova/auth'

export const useStyles = makeStyles(({ palette, spacing, typography }) => ({
  link: {
    paddingLeft: spacing(1),
    paddingTop: spacing(1),
    paddingBottom: spacing(1),
    color: palette.secondaryGrey.shade[80],
    fontWeight: typography.fontWeightRegular
  }
}))

const MarketingLinks = props => {
  const classes = useStyles()
  const { getMtUrl } = useExternalApp()
  const { isPublicTenant } = useTenant()
  // Leaving the linkProps as an empty array for future Enterprise Links addition.
  const linkProps = []

  if (isPublicTenant) {
    const consumerLinks = [
      {
        label: 'For Individuals',
        icon: <PersonOutlineOutlined />,
        href: 'https://www.mindtools.com/solutions/individuals/'
      },
      {
        label: 'For Organizations',
        icon: <CorporateFareOutlined />,
        href: 'https://www.mindtools.com/solutions/organizations/'
      },
      {
        label: 'Mindtools Store',
        icon: <StorefrontTwoTone />,
        href: getMtUrl('store')
      },
      {
        label: 'About Us',
        icon: <InfoTwoTone />,
        href: getMtUrl('about')
      },
      {
        label: 'FAQ',
        icon: <HelpTwoTone />,
        href: getMtUrl('faq')
      }
    ]
    linkProps.push(...consumerLinks)
  }

  const { isMobile } = useFormFactor()

  const renderLinks = () => {
    // Size for non-mobile should be 'm', but 'l' matches the rest of the panel text.
    // Otherwise the marketing links look too small.
    return linkProps.map(({ label, icon, href }) => (
      <ListItem disableGutters key={label}>
        <ActionButton
          variant='link'
          component={Link}
          href={href}
          size={isMobile ? 'xl' : 'l'}
          label={label}
          startIcon={icon}
          className={classes.link}
        />
      </ListItem>
    ))
  }

  return <List>{renderLinks()}</List>
}

export default MarketingLinks
