import React from 'react'
import { Link } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { routes } from '../../routes'
import { useLocationScroll, useContactDetails } from '../../hooks'
import { mtUrlMap } from '../../hooks/useExternalApp'

const InternalLink = ({ ...props }) => (
  <Link component={RouterLink} {...props} style={{ textDecoration: 'none' }} />
)

const AcceptableUseLink = ({ ...props }) => (
  <InternalLink
    to={{ pathname: routes.tandc.path, hash: '#acceptableuse' }}
    data-test='acceptable-use-policy-link'
    {...props}
  >
    {props.children || 'Acceptable Use Policy'}
  </InternalLink>
)

const PrivacyPolicyLink = ({ ...props }) => (
  <Link
    href={mtUrlMap.privacy}
    target='_blank'
    rel='noreferrer'
    data-test='privacy-policy-link'
    {...props}
  >
    {props.children || 'Privacy Policy'}
  </Link>
)

const TermsOfServiceLink = ({ ...props }) => (
  <Link
    href={mtUrlMap.termsOfService}
    target='_blank'
    rel='noreferrer'
    data-test='privacy-policy-link'
    {...props}
  >
    {props.children || 'Terms of Service'}
  </Link>
)

const TandCLink = ({ ...props }) => (
  <Link
    href={mtUrlMap.tandc}
    target='_blank'
    rel='noreferrer'
    data-test='tandc-link'
    {...props}
  >
    {props.children || 'Terms of Website Use'}
  </Link>
)

const ContactLink = ({ ...props }) => (
  <Link
    href={mtUrlMap.contact}
    target='_blank'
    rel='noreferrer'
    data-test='contact-page-link'
    {...props}
  >
    {props.children}
  </Link>
)

const CookiesPolicyLink = ({ ...props }) => (
  <Link
    href={mtUrlMap.cookie}
    target='_blank'
    rel='noreferrer'
    data-test='cookies-policy-link'
    {...props}
  >
    {props.children || 'Cookies Policy'}
  </Link>
)

const A11yStatementLink = ({ ...props }) => (
  <InternalLink
    to={routes['a11y-statement'].path}
    state={{ [useLocationScroll.SCROLLTOP]: true }}
    data-test='a11y-statement-link'
    {...props}
  >
    {props.children || 'Accessibility Statement'}
  </InternalLink>
)

const B2BPrivacyPolicyLink = ({ ...props }) => (
  <InternalLink
    to={routes['privacy-policy'].path}
    state={{ [useLocationScroll.SCROLLTOP]: true }}
    data-test='privacy-policy-link'
    {...props}
  >
    {props.children || 'Privacy Policy'}
  </InternalLink>
)

const B2BCookiesPolicyLink = ({ ...props }) => (
  <InternalLink
    to={routes['cookie-policy'].path}
    state={{ [useLocationScroll.SCROLLTOP]: true }}
    data-test='cookies-policy-link'
    {...props}
  >
    {props.children || 'Cookies Policy'}
  </InternalLink>
)

const B2BTandCLink = ({ ...props }) => (
  <InternalLink
    to={routes.tandc.path}
    state={{ [useLocationScroll.SCROLLTOP]: true }}
    data-test='tandc-link'
    {...props}
  >
    {props.children || 'Terms and Conditions'}
  </InternalLink>
)

const B2BContactLink = ({ ...props }) => {
  const { email: contactEmail } = useContactDetails()
  return (
    <Link
      href={`mailto:${contactEmail}`}
      target='_blank'
      rel='noreferrer'
      data-test='contact-page-link'
      style={{ textDecoration: 'none' }}
      {...props}
    >
      {props.children || 'Contact Us'}
    </Link>
  )
}

export {
  AcceptableUseLink,
  ContactLink,
  CookiesPolicyLink,
  PrivacyPolicyLink,
  TandCLink,
  A11yStatementLink,
  B2BPrivacyPolicyLink,
  B2BCookiesPolicyLink,
  B2BTandCLink,
  B2BContactLink,
  TermsOfServiceLink
}
