const createPurchaseEvent = (transaction, plan) => {
  const event = 'purchase'

  const payload = {
    event,
    ecommerce: {
      transaction_id: transaction.transactionId,
      value: transaction.paid,
      currency: transaction.currency,
      items: [
        {
          item_id: plan.code,
          item_name: plan.name,
          discount: transaction.discount,
          item_category: plan.trialLength,
          item_category2: plan.intervalLength,
          price: transaction.paid,
          quantity: 1
        }
      ]
    }
  }

  if (transaction.couponCode) {
    payload.ecommerce.coupon = transaction.couponCode
  }

  return {
    name: event,
    type: 'ecommerce',
    data: payload
  }
}

export default {
  createPurchaseEvent
}
