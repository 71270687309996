import React from 'react'
import { useSelector } from 'react-redux'
import { b2cSignupSlice } from '../../../reducers'
import { Step, Stepper, StepLabel } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

export const CREATE_ACCOUNT_STEP = 0
export const REVIEW_AND_PAYMENT_STEP = 1
export const VERIFICATION_STEP = 2

export const useStyles = makeStyles(({ spacing, typography, breakpoints, palette }) => ({
  root: {
    '& .MuiStepIcon-root': { color: '#DADAFE' }, // Default step icon color
    '& .MuiStepIcon-root.Mui-active': { color: '#5D5BED' }, // Active step color
    '& .MuiStepIcon-root.Mui-completed': { color: '#DADAFE' },
    width: '708px',
    padding: spacing(1),
    marginBottom: spacing(1),
    [breakpoints.down('md')]: {
      width: '100%'
    }
  }
}))

export const ProgressBar = () => {
  const classes = useStyles()
  const currentStep = useSelector(b2cSignupSlice.selectors.selectCurrentStep)
  return (
    <Stepper className={classes.root} activeStep={currentStep}>
      <Step>
        <StepLabel>Create Account</StepLabel>
      </Step>
      <Step>
        <StepLabel>Payment Information</StepLabel>
      </Step>
      <Step>
        <StepLabel>Verification</StepLabel>
      </Step>
    </Stepper>
  )
}
