import React from 'react'
import { Grid } from '@mui/material'
import { useStyles } from './style'
import MenuHeader from '../menuHeader'
import { GoogleAd } from '../../general-card/promoted-content-card'
import { useGoogleAdsense } from '../../../hooks'

const ArticleList = props => {
  const classes = useStyles(props)

  const { title, items, renderArticle, listDirection = 'column', changeSecondPosition = false } = props

  const { useAdsense } = useGoogleAdsense()

  return (
    <Grid container direction='column' className={classes.root}>
      <Grid item>
        <MenuHeader title={title} />
      </Grid>
      <Grid item container direction={listDirection} justifyContent='center' className={classes.gridList} spacing={2}>
        {(items?.slice(0, 4) || Array.from(new Array(2))).map((item, idx) => (
          <Grid item key={item?.friendlyUrl ?? idx} className={classes.item} data-test={`article-${title}-${idx}`}>
            {idx === 1 && useAdsense && changeSecondPosition ? <GoogleAd height='250px' location='explore-menu>article-list>index.js' /> : renderArticle(item)}
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

export default ArticleList
