import { useTenant } from '@emerald-works-nova/auth'
import { useMemo } from 'react'
import consts from '../consts'

const useProducts = () => {
  const tenant = useTenant()
  const isClub = tenant.pk === consts.DEFAULT_CLUB_PK

  const hasContentHubAccess = useMemo(() => {
    if (isClub) return true

    const hasContentHubField = Object.hasOwn(tenant.features, 'contentHub')
    const { contentHub } = tenant.features
    return hasContentHubField ? contentHub : true
  }, [tenant, isClub])

  const hasMsaAccess = useMemo(() => {
    const { msa } = tenant.features
    return msa
  }, [tenant])

  const hasSkillBitesAccess = useMemo(() => {
    const { skillBites } = tenant.features
    return skillBites
  }, [tenant])

  return {
    hasContentHubAccess,
    hasMsaAccess,
    hasSkillBitesAccess
  }
}

export default useProducts
