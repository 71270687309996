import React, { useMemo } from 'react'
import { useLocation, generatePath } from 'react-router-dom'
import { EntryPage, SignInForm, SignInSSO, useTenant, NavBar as PublicNavBar } from '@emerald-works-nova/auth'
import { ContactUs } from '../../../components'
import { Grid, Button, Typography } from '@mui/material'
import { routes } from '../../../routes'
import getForgotPasswordLink from '../../../helpers/getForgotPasswordLink'
import { useExternalApp, useProducts } from '../../../hooks'
import { useSession } from '@emerald-works/react-auth'
import { useEvent } from '@emerald-works/react-event-bus-client'

export const SIGN_IN_PROVIDER_TYPE_STORAGE_KEY = 'login-provider-type'
export const SIGN_IN_USERNAME_PASSWORD = 'username_password'

const SignIn = () => {
  const tenant = useTenant()
  const { hasContentHubAccess, hasMsaAccess } = useProducts()
  const location = useLocation()
  const { getMtUrl } = useExternalApp()
  const { isAuthenticated } = useSession()
  const showUsername = tenant?.usernameLogin

  const getRedirectRoute = () => {
    const redirectRoute =
      localStorage.getItem(location.state?.afterAuthLocationCookie) ||
      localStorage.getItem('after-auth-location') ||
      routes.home.path

    if (!hasContentHubAccess) {
      const msaPath = generatePath(routes['assessment-details'].path, { assessmentId: 'manager-skill-assessment' })
      const skillBitesPath = routes['skill-bites'].path

      const msaRoute = redirectRoute.includes('assessment')
        ? redirectRoute
        : msaPath
      const skillBitesRoute = redirectRoute.includes('skill-bites')
        ? redirectRoute
        : skillBitesPath

      return hasMsaAccess ? msaRoute : skillBitesRoute
    }

    return redirectRoute
  }

  const redirectRoute = getRedirectRoute()
  const samlProviders = tenant.loginProviders.filter(lp => lp.providerType === 'SAML' && lp.providerName !== 'internal')

  const homeLink = isAuthenticated ? routes.home.path : getMtUrl()
  const signupLink = getMtUrl('join')
  const forgotPasswordLink = useMemo(() => getForgotPasswordLink(tenant), [tenant])

  const [loginInitiated] = useEvent([{ eventName: 'userLoginInitiated' }])

  const handleSubmitClick = (data) => {
    const { providerType = SIGN_IN_USERNAME_PASSWORD, providerName } = data
    localStorage.setItem(SIGN_IN_PROVIDER_TYPE_STORAGE_KEY, SIGN_IN_USERNAME_PASSWORD)
    loginInitiated.trigger({ identity: { providerType, providerName } })
  }

  return (
    <Grid
      container
      direction='column'
      wrap='nowrap'
    >
      <Grid item style={{ minHeight: 85 }}>
        {tenant?.tenantType === 'public' && (
          <PublicNavBar
            link={signupLink}
            captionText="Don't have an account?"
            buttonText='Sign up'
            homeLink={homeLink}
            homeText='Back to Home'
          />
        )}
      </Grid>
      <Grid item>
        <EntryPage
          title='Let’s sign in'
          subtitle='Enter your credentials below.'
          loginProviders={tenant?.loginProviders}
          showMtLogo
          content={(
            <Grid container direction='column'>
              <>
                {tenant.loginMessageEnabled && !!tenant.loginMessage?.length && (
                  <div
                    style={{ width: '100%', marginBottom: 20, textAlign: 'center' }}
                    dangerouslySetInnerHTML={{ __html: tenant.loginMessage }}
                  />
                )}
              </>
              <SignInForm
                redirectRoute={redirectRoute}
                forgotPasswordRoute={forgotPasswordLink}
                forcePasswordChangeRoute={routes['change-password'].path}
                onSubmitClick={handleSubmitClick}
                showUsername={showUsername}
              />
              {tenant?.tenantType === 'public' && (
                <Button
                  component='a' href={signupLink} sx={{
                    marginTop: 2,
                    padding: 2,
                    backgroundColor: '#000000',
                    color: '#FFFFFF',
                    '&:hover': {
                      color: '#000000'
                    }
                  }}
                >
                  <Typography>
                    Don&apos;t have an account?&nbsp;
                  </Typography>
                  <Typography sx={{
                    fontWeight: 'bold',
                    textDecoration: 'underline'
                  }}
                  >
                    Sign up
                  </Typography>
                </Button>
              )}
            </Grid>
          )}
          // social={tenant?.tenantType === 'public' && !samlProviders.length && (
          //   <div>
          //     <SignUpSocial
          //       text='Or'
          //       loginProviders={tenant?.loginProviders}
          //     />
          //   </div>
          // )}
          sso={tenant?.tenantType !== 'public' && !!samlProviders.length && (
            <SignInSSO
              loginProviders={samlProviders}
              onSubmitClick={handleSubmitClick}
            />
          )}
          entry={tenant?.tenantType !== 'public' && (
            <ContactUs />
          )}
        />
      </Grid>
    </Grid>
  )
}

export default SignIn
