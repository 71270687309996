import GoogleAnalyticsEvent from './events'

const useGoogleAnalyticsEvent = () => {
  const sendEvent = (event) => {
    if (window.dataLayer) {
      return window.dataLayer.push(event.data)
    }
    console.warn('GA is not installed', event)
  }

  const resetEvent = (event) => {
    if (window.dataLayer) {
      return window.dataLayer.push({ [event.type]: null })
    }
  }

  const sendPurchaseEvent = (transaction, plan) => {
    if (!(transaction && plan)) {
      return console.warn('Missing GA payload', transaction, plan)
    }
    const event = GoogleAnalyticsEvent.createPurchaseEvent(transaction, plan)

    resetEvent(event)
    sendEvent(event)
  }

  return {
    sendPurchaseEvent
  }
}

export default useGoogleAnalyticsEvent
