import { useTenant } from '@emerald-works-nova/auth'
import useGroup from './useGroup'
import { FREE, BROWSER, SUSPENDED } from '../services/cognito/userGroups'
import { useMemo } from 'react'
import consts from '../consts'
import { useSession } from '@emerald-works/react-auth'

const useGoogleAdsense = () => {
  const tenant = useTenant()
  const { isInGroup } = useGroup()
  const { isAuthenticated } = useSession()

  const useAdsense = useMemo(() => {
    const isClub = tenant.pk === consts.DEFAULT_CLUB_PK
    // if we're not in the right tenant, we shouldn't see google adsense ads
    if (!isClub) {
      console.debug('*** Google Adsense hook - NOT CORRECT TENANT')
      return false
    }

    const isBrowser = isInGroup(BROWSER) || !isAuthenticated
    // if we're not a free, browser or suspended user, we shouldn't see google adsense ads
    if (!isInGroup(FREE) && !isBrowser && !isInGroup(SUSPENDED)) {
      console.debug('*** Google Adsense hook - NOT CORRECT USER GROUP')
      return false
    }

    // if we've got this far, we're good to show google adsense ads
    console.debug('*** Google Adsense hook - ENABLED, VALID TENANT ID, CORRECT USER GROUP - SHOWING AD(S)')
    return true
  }, [tenant.pk, isInGroup, isAuthenticated])

  // Rules for when to allow ads in specific content
  const contentHasAds = (content) => {
    return content?.primaryType.includes('article') &&
    content?.primaryType.length === 1 &&
    content?.googleAds !== false
  }

  return { useAdsense, contentHasAds }
}

export default useGoogleAdsense
