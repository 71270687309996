import React, { useEffect, useState } from 'react'
import { useEvent } from '@emerald-works/react-event-bus-client'
import { useTenant } from '@emerald-works-nova/auth'
import { useAlert } from '../../hooks'
import { useStyles } from './style'
import {
  Drawer,
  Typography,
  Box,
  IconButton
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

const MindsetAgent = ({ open, onClose }) => {
  const classes = useStyles()
  const { enqueueMsg } = useAlert()
  const tenant = useTenant()
  const [authToken, setAuthToken] = useState(null)
  const [appUid, setAppUid] = useState(null)
  const agentUid = process.env.REACT_APP_MINDSET_AGENT_UID
  const tenantId = tenant?.tenantKey ? `${tenant?.tenantKey}.` : ''

  const [getAuthToken] = useEvent([{
    eventName: 'getAuthToken',
    onSuccess: (payload) => {
      setAuthToken(payload.authToken)
      setAppUid(payload.appUid)
    },
    onError: (error) => {
      enqueueMsg('Unable to retrieve auth token', 'error')
      console.error('Unable to retrieve auth token', error)
    }
  }])

  useEffect(() => {
    if (!authToken && !appUid) {
      getAuthToken.trigger()
    }
  }, [authToken, appUid, getAuthToken])

  useEffect(() => {
    if (authToken && appUid) {
      try {
        window.mindset.initApp({
          appUid,
          containerId: 'agent-div',
          authToken
        })
        window.mindset.startAgentThread({
          agentUid,
          variables: { tenantId }
        })
      } catch (error) {
        console.error('Error loading Mindset Agent:', error)
      }
    } else {
      console.log('Auth token or appUid is not available yet', authToken, appUid)
      console.log('Mindset Agent is already connected')
    }
  }, [authToken, appUid, agentUid, tenantId])

  return (
    <Drawer
      open={open}
      className={classes.root}
      variant='persistent'
      anchor='right'
      data-test='mindset-agent-drawer'
    >
      <Box className={classes.container} data-test='mindset-agent-container'>
        <Box display='flex' justifyContent='flex-end' data-test='mindset-agent-close-button-container'>
          <IconButton
            aria-label='close'
            onClick={onClose}
            data-test='mindset-agent-close-button'
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography variant='h5' align='center' gutterBottom data-test='mindset-agent-title'>
          Ask M:Coach
        </Typography>
        <div id='agent-div' className={classes.agent} data-test='mindset-agent-div' />
      </Box>
    </Drawer>
  )
}

export default MindsetAgent
